import { graphql, PageProps } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";

import NavHeader from "../../components/NavHeader";

export const Page = ({ data }: PageProps<Queries.BlogPostByUidQuery>) => {
  return (
    <>
      <NavHeader />

      <Container>
        <h1>{data.prismicBlogPost?.data.title?.text}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicBlogPost?.data.snippet?.html ?? "",
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicBlogPost?.data.body?.html || "",
          }}
        />
      </Container>
    </>
  );
};

export const Head = ({ data }: PageProps<Queries.BlogPostByUidQuery>) => (
  <title>{data.prismicBlogPost?.data.title?.text} | Langsnap blog</title>
);

export const query = graphql`
  query BlogPostByUid($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      uid
      data {
        snippet {
          html
        }
        body {
          html
        }
        title {
          text
        }
      }
    }
  }
`;

export default Page;
